<template>
  <div class="properties" :class="{ 'properties-mobile': showProperties }">
    <div class="properties-body">
      <div v-if="showProperties" class="close-icon"
           @click="$emit('closeProperties')">
        <x-icon/>
      </div>
      <div class="property-inputs" v-else>
        <el-input
          tabindex="1"
          v-if="!activeProperty"
          placeholder="Search Property"
          prefix-icon="el-icon-search"
          v-model="search"
          @keyup.enter.native="selectOnEnter"/>
        <div v-else class="active-property">
          <span>{{ activeProperty.code }} - {{ activeProperty.name }}</span>
          <i @click="clearProperty" class="el-icon-close"></i>
        </div>
      </div>
      <el-card shadow="always" :body-style="bodyStyle">
        <div slot="header" class="clearfix">
          <span style="font-weight: 700; color: #8a8988">{{ $t('lbl_available_properties') }}</span>
        </div>
        <div>
          <recycle-scroller
            :items="filteredProperties"
            class="scroller"
            :item-size="65"
            key-field="id"
            v-slot="{ item, index }"
          >

              <div @click="selectProperty(item)"

                   :key="item.id"
                   class="property-item">
                <el-card style="border-radius: 0"
                         class="card-item"
                         :class="{ 'blue-color': activeProperty !== null && activeProperty.id === item.id }"
                         :body-style="{ display: 'flex', justifyContent: 'space-between' }">
                  <span class="property-name">{{ `${item.code} - ${item.name}` }}</span>
                  <i v-if="activeProperty !== null && activeProperty.id === item.id"
                     class="el-icon-check"></i>
                </el-card>
              </div>
          </recycle-scroller>
        </div>

      </el-card>
    </div>
    <div class="select">
      <h3>Select Property</h3>
      <el-select @change="selectPropertyMobile(propertyId)" v-model="propertyId"
                 filterable placeholder="Search Property" clearable style="width: 100%">
        <el-option
          v-for="item in properties"
          :key="item.id"
          :label="`${item.code} - ${item.name}`"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {XIcon} from 'vue-feather-icons'

export default {
  name: 'PropertyList',
  props: ['showProperties', 'activeProperty'],
  components: {
    XIcon
  },
  data() {
    return {
      search: '',
      propertyId: null,
    }
  },
  computed: {
    ...mapState({
      properties: state => state.current.properties
    }),
    filteredProperties() {
      if (this.properties) {
        let all_props = this.properties.slice();
        all_props.sort((a, b) => a.code.localeCompare(b.code));

        return all_props.filter(property => {
          if (property.name) {
            return (
              property.name.toLowerCase().includes(this.search.toLowerCase()) ||
              property.code.toString().toLowerCase().includes(this.search.toLowerCase())
            );
          }
          return false;
        });
      } else if (this.search === "") {
        this.properties = []
      } else {
        return []
      }
    },
    bodyStyle() {
      return {
        padding: '0'
      }
    }
  },
  methods: {
    selectPropertyMobile(propertyId) {
      if (!propertyId) {
        this.search = ""
        this.clearProperty()
        return
      }
      const foundProperty = this.properties.find((task) => task.id == propertyId)
      this.search = foundProperty.name
      this.$emit('selectProperty', foundProperty);
    },
    selectProperty(property) {
      this.propertyId = property.id
      this.$emit('selectProperty', property)
      this.$emit('closeProperties')
    },
    selectOnEnter() {
      if (this.filteredProperties.length === 1) {
        this.propertyId = this.filteredProperties[0]?.id
        this.$emit('selectProperty', this.filteredProperties[0])
        this.$emit('closeProperties')
      }
    },
    clearProperty() {
      this.propertyId = null
      this.$emit('clearProperty')
      this.$emit('closeProperties')
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #46a0fc;

.scroller {
  height: calc(100vh - 250px);
}

.properties {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;

  .close-icon {
    width: 24px;
    height: 24px;
    z-index: 1000;
    color: black;
    margin: 0 0 5px auto;
  }

  .blue-color {
    color: $blue;
  }

  .property-inputs {
    position: relative;
    margin-bottom: 30px;

    .active-property {
      display: flex;
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      justify-content: space-between;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      padding: 0 5px 0 15px;

      span {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .el-icon-close {
        cursor: pointer;
      }
    }
  }


  .property-item {
    cursor: pointer;
    min-height:65px;

    :hover {
      background-color: whitesmoke;
      color: $blue;
    }
  }

  .card-item {
    border-right: none;
    border-left: none;
    border-top: none;
  }
}

.select {
  display: none;
}

.properties-body {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}

@media (max-width: 500px) {
  .properties-body {
    display: none;
  }

  .properties-mobile {
    display: block !important;
    height: 98vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    padding: 10px;
  }
  .select {
    display: block;
    margin: 0 2rem 0 2rem;

    h3 {
      margin-top: 0;
    }
  }
}
</style>
